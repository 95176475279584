import React from "react";
import ShoppitPage from "../components/myprojects/shoppit";

const Shoppit = () => {
  return (
    <div>
      <ShoppitPage />
    </div>
  );
};

export default Shoppit;
