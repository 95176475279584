import React from "react";
import AboutPage from "../components/aboutpage";

const Aboutpage = () => {
  return (
    <div>
      <AboutPage />
    </div>
  );
};

export default Aboutpage;
