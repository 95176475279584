import React from "react";
import BidPage from "../components/myprojects/bid";

const Bid = () => {
  return (
    <div>
      <BidPage />
    </div>
  );
};

export default Bid;
