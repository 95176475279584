import React from "react";
import HolidazePage from "../components/myprojects/holidaze";

const Holidaze = () => {
  return (
    <div>
      <HolidazePage />
    </div>
  );
};

export default Holidaze;
