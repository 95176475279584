import React from "react";
import FrontPage from "../components/frontpage";

const Homepage = () => {
  return (
    <div>
      <FrontPage />
    </div>
  );
};

export default Homepage;
